import "@fontsource/noto-sans/latin-ext-400.css"
import "@fontsource/noto-sans/latin-ext-400-italic.css"
import "@fontsource/noto-sans/latin-ext-700.css"
import "../style/main.css"




setTimeout(function() {
    document.querySelectorAll('form').forEach(function (form) {

        form.addEventListener('submit', function () {
            form.querySelectorAll('.progress').forEach(function (el) {
                el.classList.remove('hidden');
            });
            form.querySelectorAll('input,textarea').forEach(function (el) {
                el.readOnly = true;
            });
        })
    });
}, 1)
